import Swiper from 'swiper';
import { Navigation, Controller } from 'swiper/modules';
Swiper.use([Navigation, Controller]);

class ProductHero {
	constructor() {
		this.init();
	}

	init() {
		const slider = new Swiper('[data-product-hero-slider]', {
			grabCursor: false,
			allowTouchMove: false,
			slidesPerView: 'auto',

			loop: true,

			modules: [Navigation, Controller],
			navigation: {
				prevEl: '.product-hero__control._prev',
				nextEl: '.product-hero__control._next',
			},
		});
	}
}

export default new ProductHero();
